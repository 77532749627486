import React from "react";
import tbn from "../../assets/tbn.png";
import newsexpress from "../../assets/newsexpres.png";
import tbngo from "../../assets/tbngo.png";
import logowhite from "../../assets/logo-white.png";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <section className={styles.main} data-testid={"footer"}>
      <div className={styles.partners}>
        <h6 className={styles.title}>Partnerzy:</h6>
        <div className={styles.partnersImage}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.tbnpolska.tv/home"
          >
            <img src={tbn} alt="tbn" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.tbnpolska.tv/home"
          >
            <img src={newsexpress} alt="newsexpress" />
          </a>
          <a rel="noreferrer" target="_blank" href="https://tbngo.pl">
            <img src={tbngo} alt="tbngo" />
          </a>
        </div>
      </div>
      <div className={styles.icons}>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.facebook.com/tbnpltv"
          data-testid="facebook-icon"
        >
          <i className="fa-brands fa-facebook-f"></i>
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.instagram.com/tbnpolska"
          data-testid="instagram-icon"
        >
          <i className="fa-brands fa-instagram"></i>
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.youtube.com/channel/UCohXyxqzCibIZl022co9Fzg"
          data-testid="youtube-icon"
        >
          <i className="fa-brands fa-youtube"></i>
        </a>
        <img src={logowhite} alt="OMB-logo" />
      </div>
    </section>
  );
};

export default Footer;
