import React from "react";
import styles from "./speaker.module.css";

const Speaker = ({
  name,
  desc,
  id,
  displayedSpeakerId,
  handleClick,
  handleClose,
  cities,
  componentToRender,
  isDesktop,
}) => {
  const isActive = displayedSpeakerId === id;

  const handleButtonClick = () => {
    if (isActive) {
      handleClose();
    } else {
      handleClick(id);
    }
  };

  return (
    <li className={`${styles.speaker} ${isActive && styles.active}`}>
      {name}{" "}
      <span
        onClick={handleButtonClick}
        className={isActive ? styles.closeBtn : styles.openBtn}
      >
        {isActive ? "zwiń..." : "rozwiń..."}
      </span>
      <div
        className={`${styles.descWrapper} ${
          isActive && styles.descWrapperActive
        }`}
      >
        <div className={styles.expandable}>
          <div>
            {!isDesktop && componentToRender}
            <p>{desc}</p>
            <div className={styles.citiesWrapper}>
              {cities.map((city) => (
                <span key={city} className={styles.city}>
                  {city}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Speaker;
