import "./App.css";
import Navbar from "./components/Navbar/navbar";
import Jumbotron from "./components/Jumbotron/jumbotron";
import Speakers from "./components/Speakers/speakers";
import Additional from "./components/Additional/additional";
import Events from "./components/Events/events";
import Ktd from "./components/Ktd/ktd";
import Media from "./components/Media/media";
import Contact from "./components/Contact/contact";
import Footer from "./components/Footer/footer";

function App() {
  return (
    <>
      <Navbar />
      <div className="App">
        <Jumbotron />
        <Speakers />
        <Additional />
        <Events />
        <Ktd />
        <Media />
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default App;
