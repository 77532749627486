import React from "react";
import ktd from "../../assets/ktd.png";
import styles from "./ktd.module.css";

const Ktd = () => {
  return (
    <section data-testid="ktd" id="ktd" className={styles.main}>
      <div className={styles.block}>
        <h1 className={styles.title}>KTD:</h1>
        <p className={styles.text}>
          Organiczna sieć Kobiet i Mężczyzn w biznesie założona przez TBN PL.
          Przedsiębiorcy, którzy w oparciu o zasady etyki chrześcijańskiej
          budują relacje, biznesy i współpracę.
        </p>
      </div>
      <div className={styles.block}>
        <img className={styles.image} src={ktd} alt="KTD" />
      </div>
    </section>
  );
};

export default Ktd;
