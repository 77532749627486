import React, { useState } from "react";
import logo from "../../assets/logo.png";
import Button from "../Button/button";
import ScrollLink from "../ScrollLink";
import styles from "./navbar.module.css";

const Navbar = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <nav data-testid="navbar" className={styles.navBar}>
      <div className={styles.logoWrapper}>
        <a href="/">
          <img className={styles.logo} src={logo} alt="logo" />
        </a>
      </div>
      <div className="menu-icon" onClick={handleClick}>
        <i
          data-testid="menu-icon"
          className={
            active
              ? `fas fa-times ${styles.icon}`
              : `fas fa-bars ${styles.icon}`
          }
        ></i>
      </div>
      <ul
        className={`${styles.navBarMenu} ${active && styles.navBarMenuActive}`}
      >
        <li>
          <ScrollLink onClick={handleClick} to="speakers">
            MS
          </ScrollLink>
        </li>
        <li>
          <ScrollLink onClick={handleClick} to="ktd">
            KTD
          </ScrollLink>
        </li>
        <li>
          <ScrollLink onClick={handleClick} to="media">
            Media
          </ScrollLink>
        </li>
        <li>
          <ScrollLink onClick={handleClick} to="contact">
            Kontakt
          </ScrollLink>
        </li>
        <li>
          <ScrollLink onClick={handleClick} to="events">
            <Button notLink={true} />
          </ScrollLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
