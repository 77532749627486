import styles from "./media.module.css";
import media from "../../assets/media.png";

const Media = () => {
  return (
    <section data-testid="media" className={styles.main}>
      <div className={styles.block}>
        <h1 className={styles.title} id="media">
          MEDIA:
        </h1>
        <p className={styles.text}>
          Platforma Wideo dla Przedsiębiorców.
          <br />
          <em>Wkrótce start</em>
        </p>
      </div>
      <div className={styles.block}>
        <img className={styles.image} src={media} alt="Media" />
      </div>
    </section>
  );
};

export default Media;
