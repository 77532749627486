import React from "react";
import styles from "./event.module.css";
import Button from "../../Button/button";

const Event = ({ link, title }) => {
  return (
    <section className={styles.main}>
      <h4 data-testid="event-title" className={styles.title}>{title}</h4>
      <Button link={link} />
    </section>
  );
};

export default Event;
