import React from "react";
import Event from "./Event/event";
import styles from "./events.module.css";

const Events = () => {
  return (
    <section data-testid="events" id="events" className={styles.main}>
      <Event
        title="Warszawa 29 Maja"
        link="https://app.evenea.pl/event/636000-16"
      />
      <Event
        title="Kraków 31 Maja"
        link="https://app.evenea.pl/event/636000-17"
      />
    </section>
  );
};

export default Events;
