import React from "react";
import styles from "./additional.module.css";
import icon from "../../assets/icon.png";

const Additional = () => {
  return (
    <section data-testid="additional" id="dodatkowo" className={styles.main}>
      <div className={styles.content}>
        <h1 className={styles.title}>Dodatkowo:</h1>
        <p data-testid="additional-text" className={styles.text}>
          Jak wykorzystać domy mediowe, telewizję i nowoczesne strategie
          marketingu{" "}
          <span className={styles.golden}>POSIADAJĄC MAŁY BUDŻET</span>
        </p>
      </div>
      <div className={styles.imageWraper}>
        <img src={icon} alt="idea" className={styles.icon} />
      </div>
    </section>
  );
};

export default Additional;
