import React, { useState } from "react";
import logo from "../../assets/logo.png";
import styles from "./jumbotron.module.css";
import Button from "../Button/button";
import ScrollLink from "../ScrollLink";

const Jumbotron = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <section data-testid="jumbotron" className={styles.main}>
      <div>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <h1 className={styles.title}>
        Ogólnopolskie <span className={styles.golden}>Meetingi Biznesowe</span>
      </h1>
      <ul className={styles.quotes}>
        <li className={styles.quote}>
          Mądrość cenniejsza jest niż złoto, zaszczytne pozycje.
        </li>
        <li className={styles.quote}>
          Rozwijamy się, nabieramy doświadczenia żeby osiągnąć sukcesy i
          pokonywać przeciwności.
        </li>
        <li className={styles.quote}>
          Przekazujemy wiedzę i doświadczenie w zaufanych sieciach relacji
          biznesowych.
        </li>
        <li id="readquotes" className={`${styles.quoteLast}`}>
          Podczas Ogólnopolskich Spotkań Biznesowych:
        </li>
        {!active && (
          <li onClick={handleClick} className={styles.btnOpen}>
            <ScrollLink onClick={handleClick} to="readquotes">
              <button className={styles.btnOpen}>rozwiń...</button>
            </ScrollLink>
          </li>
        )}
        <ul
          data-testid="quotes-list"
          className={`${styles.quotes} ${
            !active ? styles.quoteGoldListHidden : styles.quoteGoldListOpen
          }`}
        >
          <li className={styles.quoteGold}>Poznasz ludzi biznesu.</li>
          <li className={styles.quoteGold}>Wzbogacisz swoją wiedzę.</li>
          <li className={styles.quoteGold}>
            Podzielisz się swoim doświadczeniem.
          </li>
          <li className={styles.quoteGold}>
            Dodatkowo odkryjesz skuteczność niedużych budżetów na reklamę lub
            marketing.
          </li>
        </ul>
      </ul>
      <ScrollLink to="events">
        <Button notLink={true} />
      </ScrollLink>
    </section>
  );
};

export default Jumbotron;
