import React, { useState, useEffect } from "react";
import styles from "./speakers.module.css";
import Button from "../Button/button";
import ScrollLink from "../ScrollLink";
import Speaker from "./Speaker/speaker";
import krompa from "../../assets/speakers/krompa.png";
import swolyniec from "../../assets/speakers/swolyniec.png";
import kjedzejewski from "../../assets/speakers/kjedrzejewski.png";
import pgasiorowski from "../../assets/speakers/pgasiorowski.png";
import jnarog from "../../assets/speakers/jnarog.png";
import konferencja from "../../assets/konferencja.png";

const speakersData = [
  {
    id: 1,
    name: "Krzysztof Rompa",
    desc: "Założyciel i Prezes telewizji TBN Polska, właściciel spółek, mówca seminaryjny, założyciel Klubu Tysiąca Dębów.",
    image: krompa,
    cities: ["Gdynia", "Warszawa", "Kraków", "Będzin"],
  },
  {
    id: 2,
    name: "Krzysztof Jędrzejewski",
    desc: "Przedsiębiorca z ponad 30 letnim doświadczeniem. Właściciel Centrum Ogrodniczego IGLAK. Jest m.in. dominującym akcjonariuszem spółki Mostostal Zabrze S.A., w której pełni funkcję przewodniczącego rady nadzorczej.",
    image: kjedzejewski,
    cities: ["Warszawa"],
  },
  {
    id: 3,
    name: "Jacek Naróg",
    desc: "Inżynier, deweloper przemysłowy. Mówca seminaryjny. Fundator Fundacji GOOD, opiekującej się ofiarami przemocy.",
    image: jnarog,
    cities: ["Gdynia"],
  },
  {
    id: 4,
    name: "Dr. Piotr Gąsiorowski",
    desc: "Prezes Leadership Institute, Organizator GLS Polska. Mówca Seminaryjny.",
    image: pgasiorowski,
    cities: ["Kraków"],
  },
];

const Speakers = () => {
  const [displayedSpeakerId, setDisplayedSpeakerId] = useState(0);
  const [isDesktop, setIsDesktop] = useState(() => window.innerWidth >= 992);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 992);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (id) => {
    setDisplayedSpeakerId(id);
  };
  const handleClose = () => {
    setDisplayedSpeakerId(0);
  };

  const getSpeakerImage = (id) => {
    const speaker = speakersData.find((speaker) => speaker.id === id);
    return speaker ? speaker.image : konferencja;
  };

  const componentToRender = (
    <img
      src={getSpeakerImage(displayedSpeakerId)}
      alt="Speaker"
      className={styles.image}
    />
  );

  return (
    <section data-testid="speakers" id="speakers" className={styles.main}>
      <div className={styles.content}>
        <div className={styles.titles}>
          <h1 className={styles.title}>
            Wiosenne{" "}
            <span className={styles.golden}>Meetingi Biznesowe 2023.</span>
          </h1>
          <span className={styles.quote}>
            „Jak przewidzieć nadchodzące wydarzenia” <br />
            „Historia mojego biznesu” - wystąpienia doświadczonych
            przedsiębiorców
            <br />
            „Jak pojawić się w gęstym rynku konkurencji”
          </span>
        </div>
        <div>
          <h1 className={styles.titlebold}>Mówcy:</h1>
          <ul className={styles.speakers}>
            {speakersData.map((speaker) => (
              <Speaker
                key={speaker.id}
                {...speaker}
                displayedSpeakerId={displayedSpeakerId}
                handleClick={handleClick}
                componentToRender={componentToRender}
                handleClose={handleClose}
                isDesktop={isDesktop}
              />
            ))}
          </ul>
          <div className={styles.btnWrap}>
            <ScrollLink to="events">
              <Button notLink={true} />
            </ScrollLink>
          </div>
        </div>
      </div>
      {isDesktop && <div className={styles.images}>{componentToRender}</div>}
    </section>
  );
};

export default Speakers;
