import React from "react";
import styles from "./button.module.css";

const Button = ({ link, notLink }) => {
  if (notLink) {
    return (
      <span data-testid="scroll-button" className={styles.btn}>
        Zapisz się!
      </span>
    );
  }

  return (
    <a
      data-testid="event-link"
      rel="noreferrer"
      target="_blank"
      className={styles.btn}
      href={link}
    >
      Zapisz się!
    </a>
  );
};

Button.defaultProps = {
  notLink: false,
};

export default Button;
