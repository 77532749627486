import React from "react";
import styles from "./contact.module.css";

const Contact = () => {
  return (
    <section id="contact" className={styles.main} data-testid={"contact"}>
      <div className={styles.block}>
        <h1 className={styles.title}>Kontakt:</h1>

        <div className={styles.icons}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/tbnpltv"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/tbnpolska"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.youtube.com/channel/UCohXyxqzCibIZl022co9Fzg"
          >
            <i className="fa-brands fa-youtube"></i>
          </a>
        </div>
        <h4 className={styles.subtitle}>Poznaj nas:</h4>
        <p className={styles.text}>
          Zarząd Klubu Tysiąca Dębów <br /> Krzysztof Rompa <br /> Sławomir
          Wołyniec <br /> Jacek Naróg <br /> Krzysztof Jędrzejewski
        </p>
      </div>
      <div className={styles.block}>
        <ul className={styles.numbers}>
          <li>
            <a
              className={styles.websiteLink}
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/groups/549332119209244"
            >
              Grupa Facebook
            </a>
          </li>
          <li>Tel. 694 075 569</li>
          <li>kontakt@dobrybiznes.net</li>
          <li>
            <a
              className={styles.websiteLink}
              rel="noreferrer"
              target="_blank"
              href="http://dobrybiznes.net"
            >
              dobrybiznes.net
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Contact;
